// src/components/Navbar.js
import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import '../css/Navbar.css';

const NavigationBar = ({ handleLogout }) => {
    const handleLogoutClick = () => {
        handleLogout();
    };

    return (
        <div className='container fixed-top p-0'>
            <Navbar bg="dark" expand="lg" variant="dark" className='custom-navbar '>
                <Navbar.Brand href="#" className="text-white p-2">GALA</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto navbar-nav">
                        <Nav.Link href="#home" className="text-white">Videos</Nav.Link>
                        <Nav.Link href="#link" className="text-white">Photos</Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link onClick={handleLogoutClick} className="text-white">Logout</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>

    );
};

export default NavigationBar;
