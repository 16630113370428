import React from 'react';
import { useAuth } from '../context/AuthContext';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import '../css/Dashboard.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavigationBar from './Navbar';
import Body from './Body';
import TourGallery from './TourGallery';

const Dashboard = () => {
    const { handleLogout } = useAuth();

    return (
        
        <Container className='m-auto mt-2 bg-dark'>
                <NavigationBar handleLogout={handleLogout} /> {/* Render the Navbar component */}
                <Row className='mt-5 mb-1'>
                    <Col>
                        <Body /> {/* Render the Body component */}
                    </Col>
                </Row>
                <Row className='mt-3'>
                <Col>
                    <TourGallery /> {/* Render the TourGallery component */}
                </Col>
            </Row>
        </Container>
    );
};

export default Dashboard;
