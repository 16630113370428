// src/components/TourGallery.js
import React from 'react';
import { Card, CardImg, CardImgOverlay, CardTitle } from 'react-bootstrap';
import '../css/TourGallery.css';

const tourData = [
    { src: require('../images/tour_gallery/baguio.jpg'), title: 'Baguio 10+' },
    { src: require('../images/tour_gallery/sagada.jpg'), title: 'Sagada 10+' },
    { src: require('../images/tour_gallery/launion.jpg'), title: 'La Union 10+' },
    { src: require('../images/tour_gallery/zambales.jpg'), title: 'Zambales 10+' },
];

const TourGallery = () => {
    return (
        <div className="container bg-dark p-2">
            <div className="row d-flex justify-content-center">
                <div className='col-lg-12 text-center'>
                    <h3 className='text-white'>TOURS</h3>
                </div>
            </div>
            <div className="tour-gallery">
                {tourData.map((tour, index) => (
                    <Card key={index} className="tour-card">
                        <CardImg src={tour.src} alt={tour.title} className="tour-image" />
                        <CardImgOverlay className="tour-overlay">
                            <CardTitle className="tour-title">{tour.title}</CardTitle>
                        </CardImgOverlay>
                    </Card>
                ))}
            </div>
        </div>
        
    );
};

export default TourGallery;
