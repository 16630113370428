import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import '../css/Body.css';

const importAll = (r) => {
    let images = {};
    r.keys().forEach((item, index) => {
        const filename = item.split('/').pop();  // Get the filename from the full path
        images[filename] = r(item);  // Store the imported image in the images object
    });
    return images;
};

const images = importAll(require.context('../images', false, /\.(jpg)$/));
const imageTexts = [
    "'Seas The Day'",
    "'The Beauty of Tubahin'",
    "Lubang 2024",
    "Lubang 2024",
    "Lubang 2024"
    // Add more text overlays as needed
];
const Body = () => {
    const imageKeys = Object.keys(images);
    
    return(
        <div className='row d-flex justify-content-center mt-3'>
            <Carousel showThumbs={false} infiniteLoop useKeyboardArrows autoPlay dynamicHeight>
                {imageKeys.map((imageKey, index) => (
                    <div key={index} >
                        <img src={images[imageKey]} alt={` ${index + 1}`} />
                        <p className="carousel-overlay">{imageTexts[index]}</p>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default Body;